@import "../node_modules/bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');

.link-button
{
    cursor: pointer;
}

label, :focus, :active {
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

body
{
    font-family: 'Roboto', sans-serif;  
    font-weight: 500;
}

h1,h2,h3,h4,h5,h6
{
    color: #0e5abb;
    font-weight: 400;
    line-height: 1.5;
}

p
{
    color: #5f5f5f;
}

.btn-default
{
    color: #0e5abb;
    border: 1px solid #0e5abb;
    padding: 7px 22px;
    border-radius: 20px;
}

.btn-primary
{
    color: white;
    border: 1px solid #0e5abb;
    padding: 7px 22px;
    border-radius: 20px;
    background-color: #0e5abb;
}


#top-wrapper
{
    background-color: #0c204a;
}

#top-wrapper .container
{
    padding-top: 2rem;
    padding-bottom: 2rem;
}

:host ::ng-deep #top-content h1
{
    color: white;
}

:host ::ng-deep #top-content h3
{
    color: #f4c32d;
}

.form-group
{
    margin-bottom: 10px;
}